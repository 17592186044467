import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import HomeLeadspace from './partials/homeLeadspace';
import CCLStats from './partials/cclStats';
import HomeKeyAreas from './partials/homeKeyAreas';
import HomeTrends from './partials/cclTrends';
import TrendReport from './partials/trendReport';
import JourneyForm from '../components/journeyForm';
import '../styles/global.scss'

const IndexPage = () => (
  <Layout>
    <SEO
      title="Leadership Never Stops | Global Leadership"
      description="Resilience in the face of disruption starts with leadership. Discover resources, global leadership solutions, or talk to an expert about your needs."
      meta={{
        imgAlt: 'A group of people in a global leadership group gather to enact real change in their industries. '
      }}
    />
    <HomeLeadspace />
    <CCLStats />
    <TrendReport
      items={[
        {
          title: "COVID-19 Resources: Address the Needs of Today",
          btnText: "Read More",
          link: 'https://www.ccl.org/coronavirus-resources/'
        },
        {
          title: "Prepare Your Leaders for Tomorrow",
          btnText: "Explore Solutions",
          link: 'https://www.ccl.org/lead-it-yourself-solutions/digital-learning/'
        },
        {
          title: "Talk to a CCL Expert",
          btnText: "Contact Now",
          link: '#ccl-journey-form'
        }
      ]}
    />
    <HomeKeyAreas />
    <JourneyForm />
  </Layout>
);

export default IndexPage